import React from 'react';
import axios from 'cccisd-axios';
import { Redirect } from 'cccisd-react-router';
import { Formik, Form, Field, CccisdInput } from 'cccisd-formik';
import { ProfileUpdateForm as NexusProfileUpdateForm } from 'cccisd-laravel-nexus';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const ProfileUpdateForm = () => {
    const acting = Fortress.user.acting;
    const pawnId = acting.id;
    const pawnHash = acting.random_hash;

    if (!Fortress.auth()) {
        return <Redirect to="/" />;
    }

    if (acting.role.handle !== 'learner') {
        return (
            <div className={style.wrapper}>
                <h2>Update Profile</h2>
                <NexusProfileUpdateForm
                    formName="updateProfile"
                    pawnId={pawnId}
                    pawnHash={pawnHash}
                    includePawnFields
                    includeUserFields
                    includePasswordFields={false}
                    includeNotificationFields={false}
                />
            </div>
        );
    }

    async function handleSubmit(values) {
        const response = await axios.put(Boilerplate.route('api.nexus.pawn.update', { pawn: pawnId }), {
            email: values.email,
            username: values.email,
            pawnId,
            pawnHash,
        });

        if (response.data.status === 'success') {
            window.location = Boilerplate.url(Fortress.settings.config.after_login_url);
        } else if (response.data.status === 'error') {
            let submissionErrors = {};
            for (let key of Object.keys(response.data.errors)) {
                submissionErrors[key] = Array.isArray(response.data.errors[key])
                    ? response.data.errors[key].join(', ')
                    : response.data.errors;
            }

            if (submissionErrors.username) {
                submissionErrors.email = submissionErrors.username;
            }
            return Promise.reject(submissionErrors);
        } else {
            console.error(response);
        }
    }

    function validate(vals) {
        const errs = {};
        if (!vals.email) {
            errs.email = 'Required';
        }

        if (!/^[^@]{0,}@[^@.]{0,}\.[^@.]{2,}/.test(vals.email)) {
            errs.email = 'Must be valid email address';
        }

        return errs;
    }

    return (
        <div className={style.wrapper}>
            <h2>Update Profile</h2>
            <Formik initialValues={{ email: acting.user.email }} onSubmit={handleSubmit} validate={validate}>
                <Form>
                    <div style={{ margin: '22px 0' }}>
                        <Field label="Email (will also update Username)" component={CccisdInput} name="email" />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Update Profile
                    </button>
                </Form>
            </Formik>
        </div>
    );
};

export default ProfileUpdateForm;
